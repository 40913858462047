<template>
  <div>
    <!-- <img height="1" width="1" style="display:none"
       src="https://www.facebook.com/tr?id=448387636688942&ev=PageView&noscript=1"/> -->

    <img
      height="1"
      width="1"
      style="display:none"
      :src="`https://www.facebook.com/tr?id=${fbPixel}&ev=PageView`"
    />
    <img height="1" width="1" style="display:none" :src="`https://www.facebook.com/tr?id=${fbPixel}&ev=ViewContent`"/>
    <template v-if="eForm">
      <survey
        :key="keySurvey"
        :survey="survey"
      ></survey>
      <!-- <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h1>{{ eForm.title }}</h1>
              <h3>{{ eForm.description }}</h3>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col cols="12">
          </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->
    </template>
    <template v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          {{ $t('eform.notFoundById') }}
        </v-col>
      </v-row>
    </template>



    <!-- <v-dialog
     v-model="dialogAddUser"
     width="600"
     persistent>
      <v-card>
        <v-card-text>
            <v-text-field
            v-model="username"
            label="User Name"
            required
            color="#1ab394"
          ></v-text-field>
            <v-text-field
            v-model="phoneNumber"
            label="Phone Number"
            type="number"
            required
            color="#1ab394"
          ></v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded
          color="#1ab394"
          dark
          @click="saveUserData"
          >
            Save
          </v-btn>
        </v-card-actions>


      </v-card>

     </v-dialog> -->
  </div>
</template>
<script>
import * as SurveyVue from 'survey-vue'
import 'survey-vue/modern.min.css'
import * as widgets from 'surveyjs-widgets'
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import eFormMixin from '@/mixins/eFormMixin'
import { init as customWidget } from '@/components/surveyjs/customwidget'

Vue.use(LoadScript)

const { Survey } = SurveyVue
Survey.cssType = 'modern'

// widgets.icheck(SurveyVue);
widgets.select2(SurveyVue)
widgets.inputmask(SurveyVue)
widgets.jquerybarrating(SurveyVue)
widgets.jqueryuidatepicker(SurveyVue)
widgets.nouislider(SurveyVue)
widgets.select2tagbox(SurveyVue)
widgets.sortablejs(SurveyVue)
widgets.ckeditor(SurveyVue)
widgets.autocomplete(SurveyVue)
widgets.bootstrapslider(SurveyVue)
customWidget(SurveyVue)

SurveyVue.Serializer.addProperty('question', 'tag:number')

export default {
  name: 'EFormSurvey',

  components: {
    Survey,
  },

  mixins: [eFormMixin],

  data() {
    const json = {
      title: 'Default',
      showProgressBar: 'top',
      pages: [
        {
          questions: [
            {
              type: 'text',
              name: 'default',
              title: 'This is default survey',
            },
          ],
        },
      ],
    }
    const model = new SurveyVue.Model(json)

    return {
      dialogAddUser: true,
      username: null,
      phoneNumber: null,
      eForm: null,
      eFormSurveyId: null,
      survey: model,
      fbPixel: '',
      keySurvey: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  async mounted() {
    this.$vuetify.theme.isDark = false
    const { id } = this.$route.params

    if (id) {
      const self = this

      await this.connectEFormSurvey()
      await this.getEFormById({ id })

      this.eForm.survey.showProgressBar = 'top'
      this.saveEFormSurvey(this.eForm)

      this.survey = new SurveyVue.Model(JSON.parse(JSON.stringify(this.eForm.survey)))
      console.log(this.eForm)
      this.survey.onComplete.add(async function (sender) {
        // console.log(sender)
        await self.onCompleteSurveyNoParams(sender.data)
      })
      this.keySurvey++
      this.eFormSurveyId = id

      const googleTagManagerId = this.eForm.google_tag_manager_id
      this.fbPixel = this.eForm.facebook_pixel_id

      await this.$loadScript(`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`)
        .then(() => {
          window.dataLayer = window.dataLayer || []
          function gtag() {
            window.dataLayer.push(arguments)
          }
          gtag('js', new Date())

          gtag('config', googleTagManagerId)
        })
        .catch(() => {
          // Failed to fetch script
        })
    }
  },

  methods: {
    saveUserData() {
      if (this.username && this.phoneNumber) {
        this.dialogAddUser = false
      } else {
        this.dialogAddUser = true
      }
    },
  },
}
</script>
<style scoped>
.card-user {
  margin: 300px auto;
  margin-top: 50vh;
}
</style>
